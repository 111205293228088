// src/components/GroupTable.js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react';
import DeleteGroupModal from './DeleteGroupModal';
/** Actions */
import { fetchAllGroups, deleteGroup,editGroupNameAndStatus } from '../../../Actions/GroupActions';
/* Components */
import Loader from '../../Layout/Loader';
/* CSS */
import 'react-table/react-table.css';
/* Routes */
import { GET_GROUP_TO_EDIT , VIEW_GROUP_MEMBERS,GET_MEMBERS_TO_VIEW } from '../../../routes';
import DateFormatter from '../../../utils/DateFormatter';
import EditGroupModal from './EditGroupModal'; 

class GroupTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterData: "",
            isModalOpen: false,
            selectedGroup: {},
            isEditModalOpen: false,
            groupName: '',
            groupStatus: 'Inactive',
        };
    }

    componentDidMount() {
        this.props.fetchAllGroups("", this.props.page, this.props.pageSize, true);
    }
    componentDidUpdate(prevProps) {
        console.log(prevProps);
        console.log(this.props);
        if (prevProps !== this.props&& prevProps.deleteGroupSuccess !== this.props.deleteGroupSuccess&&this.props.deleteGroupSuccess) {
            this.closeDeleteModal();
            this.props.fetchAllGroups("", this.props.page, this.props.pageSize, true);
        }
    }

    openDeleteModal = (group) => {
        console.log(group);
        this.setState({ isModalOpen: true,
            selectedGroup: group
         });
    };
    closeDeleteModal = () => {
        this.setState({ isModalOpen: false });
    };
    handleDelete = () => {
        this.props.deleteGroup(this.state.selectedGroup.id);
    };

    openEditModal = (group) => {
        this.setState({
            isEditModalOpen: true,
            selectedGroup: group,
            groupName: group.name,
            groupStatus: group.status,
        });
    };

    closeEditModal = () => {
        this.setState({ isEditModalOpen: false });
    };

    handleEditGroup = () => {
        const { selectedGroup, groupName, groupStatus } = this.state;

        this.props
            .editGroupNameAndStatus(selectedGroup.id, { name: groupName, status: groupStatus })
            .then(() => {
                alert('Group updated successfully!');
                this.setState({ isEditModalOpen: false });
                this.props.fetchAllGroups(); // Refresh the table
            })
            .catch((err) => {
                alert('Failed to update group: ' + err.message);
            });
    };

    render() {
        const { groups, loading, filterOrPageLoading, error, success, totalPages, pageSize, number } = this.props;
        const { isEditModalOpen, groupName, groupStatus } = this.state;

        const columns = [
            {
                Header: "ID",
                accessor: "id",
                width: 50,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        <Link className="link" to={`${GET_MEMBERS_TO_VIEW}/${props.original.id}`}>
                            {props.original.id}
                        </Link>
                    </div>
                )
            },
            {
                Header: "Group Name",
                accessor: "name",
                width: 140,
                resizable: true,
                headerClassName: 'table-header',
                filterable: true,
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        <Link className="link" to={{
                            pathname: `${GET_MEMBERS_TO_VIEW}/${props.original.id}`,
                            state: { group: props.original },
                        }}>
                            {props.original.name}
                        </Link>
                    </div>
                )
            },
            {
                Header: "Status",
                accessor: "status",
                width: 100,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {props.original.status}
                    </div>
                )
            },
            {
                Header: "No. of Members",
                accessor: "numberofmembers",
                width: 100,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {props.original.numberOfMembers}
                    </div>
                )
            },
            {
                Header: "Created By",
                accessor: "createdBy.name",
                width: 120,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {props.original.createdBy.name}
                    </div>
                )
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                width: 120,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {DateFormatter.formatDateToDisplay(props.original.createdDate)}
                    </div>
                )
            },
            {
                Header: "Updated By",
                accessor: "updatedBy.name",
                width: 120,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {props.original.updatedBy.name}
                    </div>
                )
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                width: 120,
                resizable: true,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        {DateFormatter.formatDateToDisplay(props.original.updatedDate)}
                    </div>
                )
            },
            {
                Header: "Actions",
                width: 160,
                filterable: false,
                headerClassName: 'table-header',
                Cell: props => (
                    <div style={{ textAlign: "center" }}>
                        <Link className="link" to={{
                            pathname: `${GET_MEMBERS_TO_VIEW}/${props.original.id}`,
                            state: { group: props.original },
                        }}>
                            <Popup
                                trigger={
                                    <a className="user-lock">
                                        <FontAwesomeIcon icon="eye" />
                                    </a>
                                }
                                content='View Group'
                                position='top center'
                            />
                        </Link>
                        {/* Edit Group */}
                        <Popup
                            trigger={
                                <a className="edit-iconn" onClick={() => this.openEditModal(props.original)}>
                                    <FontAwesomeIcon icon="edit" />
                                </a>
                            }
                            content="Edit Group"
                            position="top center"
                        />
                            <Link className="link">
                                <Popup trigger={
                                    <a className="user-lock" onClick={() => this.openDeleteModal(props.original)}>
                                        <i>  <FontAwesomeIcon icon="trash" />
                                        </i>
                                    </a>}
                                    content='Delete Group'
                                    position='top center'
                                />
                            </Link>
                    </div>
                )
            }
        ];

        if (loading ) {
            return <Loader />;
        }
        return (
            <div className="mt-4 responsive-table">
                { (success || filterOrPageLoading) && error === '' ? (
                    <ReactTable
                        className="table-striped custm-table"
                        columns={columns}
                        data={groups}
                        loading={filterOrPageLoading}
                        filterable
                        manual // informs React Table that you'll be handling sorting and pagination server-side
                        defaultPageSize={pageSize}
                        pages={totalPages}
                        page={number}
                        defaultFiltered={[{ id: "name", value: this.state.filterData }]} // Use filterData state
                        onFilteredChange={filtered => {
                            // Get the value of the first filter applied, or set to empty if cleared
                            const filterValue = filtered.length > 0 ? filtered[0].value : "";
                            this.setState({ filterData: filterValue });
                            this.props.fetchAllGroups(filterValue, number, pageSize);
                        }}
                       onPageChange={page => {
                           this.props.fetchAllGroups(this.state.filterData, page, pageSize);
                       }}
                        onPageSizeChange={(pageSize, page) => {
                            this.props.fetchAllGroups(this.state.filterData, page, pageSize);
                        }}
                    />
                ) : (
                    <div>Error: {error}</div>
                )}

                <EditGroupModal
                    isOpen={isEditModalOpen}
                    onClose={this.closeEditModal}
                    title="Edit Group"
                    onSave={this.handleEditGroup}
                >
                    <div>
                        <label style={{ color: '#a7a7a7' }}>Group Name</label>
                        <input
                            type="text"
                            value={groupName}
                            onChange={(e) => this.setState({ groupName: e.target.value })}
                            style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
                        />
                    </div>
                    <div>
                        <label style={{ color: '#a7a7a7' }}>Group Status</label>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <label className="group-status-toggle">
                                <input
                                    type="checkbox"
                                    checked={groupStatus === 'Active'}
                                    onChange={(e) =>
                                        this.setState({
                                            groupStatus: e.target.checked ? 'Active' : 'Inactive',
                                        })
                                    }
                                />
                                <span className="group-status-slider"></span>
                            </label>
                            <span style={{ marginLeft: '10px' }}>
                                {groupStatus === 'Active' ? 'Active' : 'Inactive'}
                            </span>
                        </div>
                    </div>
                </EditGroupModal>
                
                {this.state.isModalOpen && (
                <DeleteGroupModal
                    groupName={this.state.selectedGroup.name}
                    onClose={this.closeDeleteModal}
                    onDelete={this.handleDelete}
                />
            )}
            </div>
        );
    }
}

GroupTable.propTypes = {
    fetchAllGroups: propTypes.func.isRequired,
    groups: propTypes.array.isRequired,
    loading: propTypes.bool.isRequired,
    error: propTypes.string,
    success: propTypes.bool.isRequired,
    totalPages: propTypes.number.isRequired,
    pageSize: propTypes.number.isRequired,
    number: propTypes.number,
    filterOrPageLoading: propTypes.bool.isRequired
};

const mapStateToProps = state => ({
    groups: state.group.items,
    loading: state.group.loading,
    error: state.group.error_all_loading_groups,
    success: state.group.getting_allGroups_successfully,
    totalPages: state.group.totalPages,
    pageSize: state.group.pageSize,
    number: state.group.number,
    filterOrPageLoading: state.group.filterOrPageLoading,
    deleteGroupSuccess: state.group.delete_group_successfully
});

export default withRouter(
    connect(
        mapStateToProps,
        { fetchAllGroups, deleteGroup,editGroupNameAndStatus }
    )(GroupTable)
);
