import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Button, Modal, Icon, Popup } from 'semantic-ui-react';
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import {
    sendNotificationToSelectedUsers,
    sendNotificationToAllCustomers} from '../../Actions/NotificationsActions';

import { emptyCustomer } from '../../Actions/CustomerActions';

const INITIAL_STATE = {
    showModal: '',
    title: '', 
    body: '',  
    validationError: true,
    validationMessage: ''
};

class SendCustomerNotification extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            };
        }
        return state;
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces }, () => {
            // Check validation after each change
            if (this.state.title && this.state.body) {
                this.setState({ validationError: false, validationMessage: '' });
            } else {
                this.setState({ validationError: true, validationMessage: 'Title and Body cannot be empty' });
            }
        });
    }

    handleSubmit = () => {
        const { checkedItems, sendNotificationToAllCustomers, sendNotificationToSelectedUsers, emptyCustomer } = this.props;
        const { title, body } = this.state;
    
        if (checkedItems.size === 0) {
            sendNotificationToAllCustomers(title, body);
        } else {
            const selectedCustomerUIds = Array.from(checkedItems.keys());
            sendNotificationToSelectedUsers({ uids: selectedCustomerUIds, title, body });
        }
    
        this.setState({ title: '', body: '' });
        this.closeModal();
        emptyCustomer();
    
    };
    

    closeModal = () => {
        this.setState({ showModal: false, validationError: true, validationMessage: '' });
    }

    openModal = () => {
        this.setState({ showModal: true, title: '', body: '' });
    }

    render() {
        const { title, body, validationError } = this.state;
        const { success, successAllCustomers } = this.props;

        if (success === false || successAllCustomers === false) {
            this.props.emptyCustomer();
        } else if (success === true || successAllCustomers === true) {
            alert("Notification Sent Successfully");
            this.props.emptyCustomer();
            this.closeModal();
        }

        return (
            <Modal
                id="modal"
                trigger={
                    <Popup trigger={
                        <button type="submit" className="customer_icons" onClick={this.openModal}>
                            <Icon name="bell" />
                        </button>
                    } content='Send Notification' position='top center' />
                }
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Send Notification</Modal.Header>
                <Modal.Content>
                    <Modal.Actions>
                        <div className="container">
                            <form className="white" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="title" className="label">Notification Title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            id="notification_title"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            value={title}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="body" className="label">Notification Body</label>
                                        <textarea
                                            name="body"
                                            id="notification_body"
                                            className="form-control"
                                            rows="6"
                                            onChange={this.handleChange}
                                            value={body}
                                        />
                                    </div>
                                </div>

                                <div className="input-field">
                                    <Button disabled={validationError} onClick={this.handleSubmit} id="saveButton">
                                        Send
                                    </Button>
                                </div>
                                {validationError && <p style={{ color: "red" }}>{this.state.validationMessage}</p>}
                            </form>
                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}

SendCustomerNotification.propTypes = {
    sendNotificationToSelectedUsers: propTypes.func.isRequired,
    sendNotificationToAllCustomers: propTypes.func.isRequired,
    emptyCustomer: propTypes.func.isRequired
};

const mapStateToProps = state => ({
    showModal: state.customers.notificationShowModal,
    error: state.customers.send_notification_error,
    success: state.customers.send_notification_sucessfully,
    successAllCustomers: state.customers.send_notification_to_AllCustomers_sucessfully
});

export default withFirebase(
    connect(mapStateToProps, {
        sendNotificationToSelectedUsers,
        emptyCustomer,
        sendNotificationToAllCustomers
    })(SendCustomerNotification)
);
