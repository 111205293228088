import axios from 'axios';
import { SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR, SEND_NOTIFICATION_LOADING } from './Types';

export const sendNotificationToSelectedUsers = ({ uids, title, body }) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        try {
            const token = await firebase.auth().currentUser.getIdToken();
            
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization-Firebase': token 
                }
            };

            const response = await axios.post('/notify-fcm/sendToSelectedUsers', 
                { uids, title, body }, 
                config
            );

            dispatch({
                type: SEND_NOTIFICATION_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            console.error("Error sending notification:", error);
            dispatch({
                type: SEND_NOTIFICATION_ERROR,
                payload: "Failed to send notification to selected users."
            });
        }
    };
};

// Action for sending notification to all parents
export const sendNotificationToAllCustomers = (title, body) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        try {
            const token = await firebase.auth().currentUser.getIdToken();
            
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization-Firebase': token 
                }
            };

            const response = await axios.post(
                `/notify-fcm/sendToAllParents?title=${title}&body=${body}`,
                {},
                config
            );

            dispatch({
                type: SEND_NOTIFICATION_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            console.error("Error sending notification to all customers:", error);
            dispatch({
                type: SEND_NOTIFICATION_ERROR,
                payload: "Failed to send notification to all parents."
            });
        }
    };
};


export const sendNotificationToAllPartnerParents = (title, body) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        try {
            const token = await firebase.auth().currentUser.getIdToken();
            
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization-Firebase': token 
                }
            };

            const response = await axios.post(
                `/notify-fcm/sendAllByPartner?title=${title}&body=${body}`,
                {},
                config
            );

            dispatch({
                type: SEND_NOTIFICATION_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            console.error("Error sending notification to all customers:", error);
            dispatch({
                type: SEND_NOTIFICATION_ERROR,
                payload: "Failed to send notification to all parents."
            });
        }
    };
};

// Clear the notification state
export const emptyCustomer = () => {
    return {
        type: 'EMPTY_CUSTOMER'
    };
};
